/*
** Theme Variables
*/
:root {
    --primary: #173F5F;
    --primary-a: #0F2A3F;
    --secondary: #000000;
    --secondary-a: #000000;
    --body: var(--secondary);

    --white: #ffffff;
    --black: #000000;
    --red: #FF0000;
    --redorange: #FF3737;
    --green: #11D900;
    --yellow: #FCC400;
    --skyblue: #9ECDE3;

    --siteradius: 10px;
}

/*
** Fonts
*/
@font-face {
    font-family: 'inter';
    src: url('../../fonts/Inter-Regular.woff2') format('woff2'),
        url('../../fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter-md';
    src: url('../../fonts/Inter-Medium.woff2') format('woff2'),
        url('../../fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter-sb';
    src: url('../../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../../fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter-bd';
    src: url('../../fonts/Inter-Bold.woff2') format('woff2'),
        url('../../fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*
** Font Icons
*/
@font-face {
    font-family: 'hpc';
    src: url('../../fonts/icons/hpc.ttf?x2q4ys') format('truetype'),
        url('../../fonts/icons/hpc.woff?x2q4ys') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'hpc';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    will-change: transform, opacity;
}

.icon-view:before {
    content: "\e91c";
}

.icon-hex-line:before {
    content: "\e91b";
}

.icon-clock:before {
    content: "\e919";
}

.icon-history:before {
    content: "\e918";
}

.icon-lighting:before {
    content: "\e91a";
}

.icon-error:before {
    content: "\e917";
}

.icon-cross:before {
    content: "\e916";
}

.icon-hpc-hex:before {
    content: "\e915";
}


.icon-search:before {
    content: "\e914";
}

.icon-tick-circle:before {
    content: "\e913";
}

.icon-left-arrow:before {
    content: "\e912";
}

.icon-alt-eye:before {
    content: "\e911";
}

.icon-eye:before {
    content: "\e910";
}

.icon-close-circle:before {
    content: "\e90f";
}

.icon-info:before {
  content: "\e90d";
}

.icon-close:before {
  content: "\e90e";
}

.icon-trash:before {
    content: "\e908";
}

.icon-sensor:before {
    content: "\e909";
}

.icon-arrow-circle:before {
    content: "\e90a";
}

.icon-calendar:before {
    content: "\e90b";
}

.icon-edit:before {
    content: "\e90c";
}

.icon-light:before {
    content: "\e904";
}

.icon-stop:before {
    content: "\e905";
}

.icon-play:before {
    content: "\e906";
}

.icon-pause:before {
    content: "\e907";
}

.icon-check-circle:before {
    content: "\e902";
}

.icon-modes:before {
    content: "\e903";
}

.icon-bell:before {
    content: "\e900";
}

.icon-warning:before {
    content: "\e901";
}







.icon-pumping:before{
    content: url("/src/images/icons/pump.svg");
}
/*
** Custom Reset
*/
img {
    max-width: 100%;
    height: auto;
}

a, span, i, label {
    display: inline-block;
}

a {
    color: var(--primary);
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

a:hover {
    color: var(--primary-a);
}

a:focus {
    outline: none;
}

strong, b {
    font-family: 'inter-bd', sans-serif;
    font-weight: normal;
}

header ul,
footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

p:last-child,
ul:last-child,
li:last-child {
    margin-bottom: 0;
}

button {
    padding: 0;
    border: 0;
    cursor: pointer;
    background-color: transparent;
}

button:disabled {
    cursor: none;
    pointer-events: none;
}

button,
button:focus {
    outline: 0;
    box-shadow: none;
}

input:focus {
    outline: none;
    box-shadow: none;
}

/*
** Globals
*/

/*Typo*/
body {
    font-family: 'inter', sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: var(--body);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'inter-bd';
    font-weight: normal;
    font-style: normal;
}

th {
    font-family: 'inter-md', sans-serif;
    font-style: normal;
    font-weight: 500;
}

/*Background Utilities*/
.bg--primary { background-color: var(--primary); }
.bg--sky { background-color: var(--skyblue); }

/*Text Colors*/
.text--red { color: var(--red); }
.text--redorange { color: var(--redorange); }
.text--green { color: var(--green); }
.text--yellow { color: var(--yellow); }

/*Text Utilities*/
.text-rg { font-family: 'inter', sans-serif; }
.text-md { font-family: 'inter-md', sans-serif; }
.text-sb { font-family: 'inter-sb', sans-serif; }
.text-bd { font-family: 'inter-bd', sans-serif; }

.icon-edit { color: var(--primary); }
.icon-trash { color: var(--red); }

.text-18 { font-size: 18px; line-height: 26px; }

/*
** Buttons
*/
.bttn {
    font-family: 'inter-md', sans-serif;
    font-size: 18px;
    padding: 0 22px;
    height: 46px;
    line-height: 42.8px;
    border: 1.6px solid transparent;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}

.bttn.bttn-md {
    padding: 0 28px;
}

.bttn.bttn-sm {
    padding: 0 8px;
}

.bttn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
}


.bttn-primary:hover,
.bttn-primary:focus,
.bttn-primary:active {
    background-color: var(--primary-a);
    border-color: var(--primary-a);
    color: var(--white);
}

.bttn-primary-inverse {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
}

.bttn-primary-inverse:hover,
.bttn-primary-inverse:focus,
.bttn-primary-inverse:active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
}

.bttn-secondary-inverse {
    background-color: transparent;
    border-color: var(--secondary);
    color: var(--secondary);
}

.bttn-secondary-inverse:hover,
.bttn-secondary-inverse:focus,
.bttn-secondary-inverse:active {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--white);
}

.bttn-header {
    font-size: 16px;
    line-height: 36px;
    width: auto;
    height: 40px;
    padding: 0 12px;
}

.bttn-link {
    display: inline-flex;
    align-items: center;
    color: var(--primary);
}

.bttn-link span {
    text-decoration: underline;
    vertical-align: middle;
}

.bttn-link i {
    vertical-align: middle;
    margin-left: 7px;
}

.bttn-close {
    font-size: 17px;
    line-height: 32px;
    width: 32px;
    height: 32px;
    color: var(--white);
    background-color: var(--primary);
    text-align: center;
    border-radius: 5px;
}

.bttn-close:hover {
    background-color: var(--primary-a);
}

.dpopup .bttn-md {
    font-size: 14px;
    line-height: 33px;
    height: 37px;
    min-width: 75px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
}

.dpopup-adduser .bttn {
    font-size: 14px;
    height: 37px;
    line-height: 33px;
}

.dpopup-backart .dpopup-wrapper {
    background-image: url('../../images/hex-backart.svg');
    background-size: 257px auto;
    background-position: center center;
    background-repeat: no-repeat;
}


/*
** Bootstrap Extends & Overrides
*/
.container, .container-fluid, .row>* {
    padding-left: 15px;
    padding-right: 15px;
}

.visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.overflow-hidden {
    overflow: hidden;
}

/*
** Containers & Wrappers
*/
.container {
    max-width: 1430px;
}

/*
** Header
*/
.sitehead {
    padding: 8px 0px;
    background-color: var(--white);
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
}

.head-row {
    display: flex;
    align-items: center;
}

.hpc-logo, .hpc-logo img {
    vertical-align: middle;
}

.head-right {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.head-right>div+div {
    margin-left: 32px;
}

.head-user .icon-hpc-hex {
    font-size: 30px;
    color: var(--primary);
    padding-left: 8px;
}

.headbell {
    position: relative;
    vertical-align: middle;
}

.headbell .icon-bell {
    font-size: 26px;
    line-height: 26px;
    vertical-align: middle;
}

.headbell-count {
    position: absolute;
    top: 0px;
    right: 0;
    font-family: 'inter-md', sans-serif;
    font-size: 12px;
    background: var(--red);
    color: var(--white);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    transform: translate(63%, -19%);
    will-change: transform, opacity;
}

.head-alerts .icon-warning {
    font-size: 25px;
    line-height: 25px;
    color: var(--yellow);
    vertical-align: middle;
}

.head-user {
    position: relative;
    cursor: pointer;
}

.menudrop {
    position: absolute;
    left: 50%;
    top: calc(100% + 9px);
    transform: translateX(-50%);
    opacity: 0;
    will-change: transform, opacity;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 9;
}

.head-user:hover .menudrop {
    opacity: 1;
    visibility: visible;
    will-change: transform, opacity;
}

.menudrop .bttn {
    font-size: 14px;
    width: 68px;
    height: 35px;
    line-height: 31px;
    text-align: center;
    padding: 0;
    border-radius: 5px;
}

.head-notify {
    position: relative;
}

.notify {
    position: absolute;
    right: 0;
    top: calc(100% + 12px);
    width: 368px;
    border-radius: 10px 0px 10px 10px;
    box-shadow: 0px 1px 15px 5px rgba(42, 169, 199, 0.2);
    background-color: var(--white);
    transform: translateY(5px);
    will-change: transform, opacity;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}

.notify.notify-open {
    transform: translateY(0);
    will-change: transform, opacity;
    opacity: 1;
    visibility: visible;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  

.notify-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 11px 15px;
    margin-bottom: 7.5px;
    border-bottom: 1px solid #E1E1E1;
}

.notify-close {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 14px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    color: var(--white);
    background-color: var(--primary);
    text-align: center;
    border-radius: 5px;
}

.notify-close:hover {
    color: var(--white);
    background-color: var(--primary);
}

.notif-head--title {
    font-family: 'inter-md', sans-serif;
    font-size: 16px;
    line-height: 20px;
}

.notify-body {
    max-height: 230px;
    padding: 0 11px 0;
    overflow: auto;
}

.notify-item {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    background: #EAF1FF;
    border-radius: 5px;
}

.notify-item + .notify-item {
    margin-top: 10px;
}

.notify-item .icon-close {
    align-self: flex-start;
}

.notify-item .icon-info {
    font-size: 26px;
    color: var(--primary);
    margin-right: 11px;
}

.notify-cont {
    font-size: 14px;
    line-height: 21px;
    flex: 1 0 calc(100% - 26px - 11px);
    max-width: calc(100% - 26px - 11px - 18px);
}

.notify-item--close {
    align-self: flex-start;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.notify-footer {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: var(--primary);
    padding: 16px 11px 20px;
    text-decoration: underline;
}

/*Login / Signup*/
.showmob { display: none; }

.login-row {
    display: flex;
    flex-wrap: wrap;
}

.login-row > div {
    flex: 1 0 50%;
    max-width: 50%;
}

.login-left {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 40px 20px;
    position: relative;
    text-align: center;
}

.login-leftwrap {
    position: relative;
}

.login-right {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 40px 20px;
    background: linear-gradient(152.11deg, #173F5F 0%, #647F94 100%);;
}

.loginbox {
    width: 604px;
    max-width: 100%;
    padding: 34px 53px;
    border-radius: var(--siteradius);
    background-color: #FFFFFF;
    min-height: 550px;
}

.loginbox .tabs {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.loginbox .tabs-tab {
    flex: 1 0 50%;
    max-width: 50%;
}

.tabs-link {
    display: block;
    font-family: 'inter-md', sans-serif;
    font-size: 24px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0px 20px;
    color: var(--primary);
    text-align: center;
    border-radius: calc(var(--siteradius) / 2);
}

.panels-item {
    display: none;
}

.panels-item.active {
    display: block;
}

.tabs-link.active {
    background-color: var(--primary);
    color: var(--white);
}

.loginbox .panels {
    margin-top: 80px;
}

.loginbox form {
    width: 376px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.loginbox label {
    display: block;
    font-family: 'inter-md', sans-serif;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
    color: #1B1B1B;
}

.loginbox .input-group {
    position: relative;
    margin-bottom: 24px;
}

.input-control {
    width: 100%;
    height: 49px;
    padding: 0 16px;
    border: 1px solid #8A8A8A;
    border-radius: 6px;
}

.input-control[type="password"] {
    padding-right: 46px;
}

.loginbox input::placeholder {
    color: #AAAAAA;
}

.form-action {
    margin-top: 46px;
    text-align: center;
}

.form-action .bttn {
    font-family: 'inter-md', sans-serif;
}

.passeye {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    right: 17px;
    color: #4D4D4D;
}

.passeye-hide {
    display: none;
}

/*
** Dashboard
*/

/*Main*/
.main {
    padding-top: 40px;
    padding-bottom: 20px;
    min-height: calc(100vh - 80.68px);
    background-color: var(--skyblue);
}

.dashpanels {
    position: relative;
    overflow: hidden;
}

.panelcard {
    min-height: 720px;
}

.panelcardbox, .page-card{
    padding: 27px 20px 100px;
    border-radius: var(--siteradius);
    background-color: var(--white);
}

.panelcard-lt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.panelcard-wrap {
    flex: 1 0 100%;
    width: 100%;
}

/*Pool Temprature*/
.pooltemp {
    position: absolute;
    right: 24px;
    top: 27px;
    font-family: 'inter-md', sans-serif;
    font-size: 20px;
    line-height: 24px;
}

.pooltemp span { color: var(--primary); }

/*Hexart Lighting*/
.hexart {
    position: relative;
    width: 249px;
    margin: 13px auto 14px;
}

.hexart-bs, .hexart-bb ,.hexartp-bs, .hexartp-bb{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    will-change: transform, opacity;
}

.hexart-bs,.hexartp-bs { width: calc(100% - 31px); }

.hexart-bb, .hexartp-bb { width: calc(100% - 145px); z-index: 3; }

.hexlabel {
    display: block;
    font-family: 'inter-md', sans-serif;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    width: 100%;
    margin-bottom: 16px;
}

.hexstatus {
    display: block;
    font-family: 'inter-bd', sans-serif;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 16px;
    color: var(--primary);
}

.hexlt-btn {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 2px solid var(--primary);
    border-radius: 100%;
    background-color: #CCCCCC;
    outline: none;
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.hexlt-btn:hover,
.hexlt-btn:active {
    outline: none;
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 8px rgba(0, 0, 0, 0.5);
}

.hexlt-btn:focus {
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 4px rgba(0, 0, 0, 0.25);
}



.hexp-btn {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 2px solid var(--primary);
    border-radius: 100%;
    background-color: #CCCCCC;
    outline: none;
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.hexp-btn:hover,
.hexp-btn:active {
    outline: none;
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 8px rgba(0, 0, 0, 0.5);
}

.hexp-btn:focus {
    box-shadow: inset 0px 0px 0px 7px white, 0px 2px 4px rgba(0, 0, 0, 0.25);
}

/*Hex Cover STARTS*/
.panelcard-pc {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.hexart-cover .hexart-rg {
    position: relative;
    z-index: 5;
}

.hexbars {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    will-change: transform, opacity;
}

.hexbars-over {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    width: 229px;
    height: 100%;
    margin: 0 auto;
    background: #ffffff;
    z-index: 1;
}

.hexbars svg {
    display: block;
    width: calc(100% - 20px);
    height: auto;
    margin: 0 auto;
}

.panelcard-pc .hexlabel {
    font-size: 22px;
    line-height: 1em;
    margin-bottom: 10px;
}

.coverbtns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 212px;
    margin: 0 auto 30px;
}

.coverbtn {
    position: relative;
    width: 60px;
    height: 60px;
    border: 2px solid var(--primary);
    border-radius: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.coverbtn.disabled {
    opacity: 0.6;
    color: #7F7F7F;
    background-color: #D9D9D9;
    will-change: transform, opacity;
}

.coverbtn.disabled .coverbtn-def,
.coverbtn.disabled .coverbtn-pl,
.coverbtn.disabled .coverbtn-pa {
    cursor: no-drop;
    pointer-events: auto;
}

.coverbtn-lbl {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    margin: 0 auto;
    font-family: 'inter-sb', sans-serif;
    display: block;
    width: calc(100% + 30px);
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    transform: translateX(-50%);
    will-change: transform, opacity;
}

.coverbtn-def {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.coverbtn-def img,
.coverbtn-def svg {
    width: 30px;
    height: auto;
}

.coverbtn-def i {
    font-size: 30px;
    line-height: 30px;
    color: var(--primary);
}

.coverbtn-pp > div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-size: 45px;
    line-height: 58px;
    text-align: center;
    color: var(--primary);
    margin: 0 auto;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
    z-index: 3;
}

.coverbtn-pl,
.coverbtn-pa,
.coverbtn.disabled .coverbtn-pp > div {
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity;
}

.coverbtn-pl.enable,
.coverbtn-pa.enable {
    opacity: 1;
    visibility: visible;
    will-change: transform, opacity;
}

.coverprog {
    display: none;
    width: 100%;
    flex: 1 0 100%;
}

.panelcard-pc[data-pcinprog="0"] .coverprog {
    opacity: 0.6;
    will-change: transform, opacity;
}

.coverprogwrap {
    font-family: 'inter-md', sans-serif;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex: 1 0 350px;
    justify-content: center;
    align-items: center;
    width: 350px;
    max-width: 100%;
    padding-top: 20px;
    padding-bottom: 46px;
    margin: 0 auto;
    color: var(--primary);
}

.coverprogwrap::before {
    content: "0%";
    margin-right: 8px;
}

.coverprogwrap::after {
    content: "100%";
    margin-left: 8px;
}

.progtrack {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 0 100%;
    max-width: 229px;
    height: 17px;
    padding: 1px 2px;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: calc(var(--siteradius) * 10);
    background-color: #F1F1F1;
}

.progfill {
    position: relative;
    width: 0;
    height: 13px;
    border-radius: calc(var(--siteradius) * 10);
    background-color: #40D0B4;
    overflow: visible !important;
}

.progcount {
    position: absolute;
    top: 100%;
    right: 0%;
    margin-top: 6px;
    transform: translateX(50%);
    will-change: transform, opacity;
}

.panelcard-pc[data-pcprog="0"] .progcount {
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity;
}

.covertime {
    display: none;
    flex: 1 0 100%;
    width: 100%;
    color: var(--primary);
}

/*Hex Cover ENDS*/

/*Bottom Status Bar*/
.dashstatus {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'inter-sb', sans-serif;
    font-size: 24px;
    line-height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 69px;
    color: var(--white);
    border-bottom-left-radius: var(--siteradius);
    border-bottom-right-radius: var(--siteradius);
    background-color: var(--primary);
}

.dashstatus.error { background-color: #FF3333; color: var(--white); }
.dashstatus:not(.error) .dashstatus-error { display: none; }
.dashstatus span { margin-left: 5px; }
.dashstatus.error .dashstatus-on { display: none; }

/*Scheduling */
.sctabs {
    font-family: 'inter-md', sans-serif;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    list-style: none;
    max-width: max-content;
    padding: 18px 21px;
    margin: 0 auto 82px;
    border-radius: var(--siteradius);
    background: var(--white);
    text-align: center;
}

.sctabs-tab  { flex: 1 0 auto; }

.sctabs-tab + .sctabs-tab { margin-left: 30px; }

.sctab-link {
    display: block;
    padding: 10px 10px;
    border-radius: var(--siteradius);
    background-color: transparent;
}

.sctab-link.active { background-color: var(--primary); color: var(--white) }

.scpanels {
    position: relative;
    padding: 24px;
    border-radius: var(--siteradius);
    background-color: var(--white);
}

.scpane {
    display: none;
}

.scpane.active {
    display: block;
}

.addsched {
    position: absolute;
    bottom: calc(100% + 20px);
    right: 0;
}

.addsched .bttn {
    font-family: 'inter-md', sans-serif;
    font-size: 18px;
    line-height: 44px;
    height: 46px;
    padding: 0 20px;
}

/*Table*/
.table-responsive {
    width: 100%;
    overflow: auto;
}

.page-table {
    width: 100%;
    white-space: nowrap;
}

.page-table th span {
    display: block;
    font-family: 'inter-md', sans-serif;
    font-size: 16px;
    line-height: 16px;
    width: calc(100% - 10px);
    padding: 8px 10px;
    margin: 0 auto;
    background: var(--primary);
    border-radius: calc(var(--siteradius) / 2);
    color: var(--white);
    margin-bottom: 16px;
}

.page-table th:first-child span { width: calc(100% - 5px); margin-left: 0; }

.page-table th:last-child span { width: calc(100% - 5px); margin-right: 0; }

.page-table th,
.page-table td {
    padding: 0;
    border-spacing: 0;
    min-width: 113px;
}

.page-table td span {
    display: block;
    padding: 8px 14px;
    background-color: rgba(23, 63, 95, 0.2);
    margin-bottom: 8px;
    overflow: hidden;
}

.page-table td:first-child span {
    border-top-left-radius: calc(var(--siteradius)/2);
    border-bottom-left-radius: calc(var(--siteradius)/2);
}

.page-table td:last-child span {
    border-top-right-radius: calc(var(--siteradius)/2);
    border-bottom-right-radius: calc(var(--siteradius)/2);
}

.page-table .sched-edit {
    font-size: 19.53px;
    line-height: 17.53px;
}

.page-table .sched-view {
    font-size: 16.69px;
    line-height: 12.69px;
    color: #02C8A1;
    margin-left: 9px;
}

.page-table .sched-trash {
    font-size: 18.55px;
    line-height: 17.55px;
    margin-left: 11px;
}

.alert-trashdone { display: none; }

.alert-trashdone span { display: block; }

.alert-trashdone.dpopup-alerttxt > i {
    display: block;
    font-size: 49px;
    line-height: 1;
    margin-top: 35px;
}

/*
** Admin
*/
.page-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.searchbox {
    display: flex;
    align-items: center;
}

.input-control.input-search {
    position: relative;
    width: 512px;
    height: 40px;
    line-height: 38px;
    padding-left: 47px;
    margin-right: 16px;
    border: 1px solid rgba(23, 63, 95, 0.5);
    background-color: rgb(255, 255, 255);
    background-image: url(../../images/search.svg);
    background-repeat: no-repeat;
    background-size: 19.5px;
    background-position: left 17.25px center;
}

.input-search::placeholder {
    font-size: 16px;
    line-height: 16px;
    color: #979797;
}

.searchbox .bttn {
    font-size: 16px;
    line-height: 36px;
    width: auto;
    height: 40px;
    padding: 0 12px;
}

.userform {
    max-width: 450px;
    margin: 32px auto 0;
}

.input-group {
    position: relative;
}

.form-inline .input-group {
    display: flex;
    align-items: center;
}

.form-inline .input-group + .input-group {
    margin-top: 12px;
}

.userform .input-group label {
    display: flex;
    justify-content: space-between;
    flex: 0 0 84px;
    font-family: 'inter-md', sans-serif;
    width: 84px;
    margin-right: 9px;
    text-align: left;
    color: #171717;
}

.userform .input-group label::after {
    content: ":";
}

.userform .input-control {
    flex: 1 0 calc(100% - 84px - 9px);
    line-height: 32px;
    height: 36px;
    border-color: rgba(23, 63, 95, 0.5);
}

.userform .passeye {
    bottom: 5px;
}

.dpopup .bttn-md {
    font-size: 14px;
    line-height: 33px;
    height: 37px;
    min-width: 75px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
}

.dpopup-adduser .bttn {
    font-size: 14px;
    height: 37px;
    line-height: 33px;
}



/*
** Footer
*/
.navbar {
    margin-top: 32px;
}

.navbar-list {
    display: flex;
    justify-content: center;
    padding: 10px 34px;
    list-style: none;
    border-radius: var(--siteradius);
    background-color: var(--white);
}

.navbar-list li { flex: 1 0 calc(25% - 60px); }

.navbar-list .tabs-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    line-height: 50px;
    height: 50px;
    max-width: 305px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
}

.navbar-list .tabs-link i {
    font-size: 32px;
    line-height: inherit;
    margin-right: 8px;
}

/*
** Popups
*/
.dpopup-on {
    overflow: hidden;
}

.dpopup {
    --dpopwid: 830px;
    --dpoprad: 10px;
    --dpopbg: var(--white);
}

.dpopup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.dpopup-wrapper {
    position: relative;
    width: var(--dpopwid);
    max-width: calc(100% - 30px);
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: var(--dpoprad);
    background: var(--dpopbg);
}

.dpopup-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 36px;
}

.dpopup-head--title {
    font-family: 'inter-md', sans-serif;
    font-size: 24px;
    line-height: 1.26em;
    padding: 0;
    margin: 0;
}

.dpopup-head .dpopup-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 20px;
    transition: 0.3s ease-in-out;
}

.dpopup-body {
    padding: 37px;
    padding: 17px 57px 37px;
}

.dpopup-title {
    font-family: 'inter-md', sans-serif;
    font-size: 26px;
    line-height: 1.36em;
    margin: 0 auto;
    color: var(--primary);
}

.dpopup-alerttxt span {
    display: inline;
    vertical-align: middle;
}

.dpopup-alerttxt > i {
    display: inline;
    vertical-align: middle;
}

.popup-action {
    display: flex;
    justify-content: center;
    margin-top: 36px;
}

.popup-action .bttn + .bttn {
    margin-left: 60px;
}

/*Sensor Popup*/
.dpopup-sensorfail .dpopup-wrapper { --dpopwid: 511px; }

.sensorfail-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'inter-sb', sans-serif;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
}

.sensorfail-title i {
    font-size: 40px;
    margin-right: 12px;
}

.sensorfail-cont h3 {
    font-family: 'inter-sb', sans-serif;
    font-size: 24px;
    color: #383838;
}

.sensorfail-cont i {
    font-size: 40px;
    margin-bottom: 32px;
    color: #E70000;
}

/*View Schedule Popup*/
.dpopup.dpopup-viewsc {
    --dpopwid: 993px;
}

.dpopup-viewsc .dpopup-body {
    padding-top: 0;
}

.dpopup-viewsc .dpopup-title {
    font-family: 'inter-sb', sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: var(--primary);
    text-transform: uppercase;
}

.dpopup-viewsc .dpopup-title span {
    text-decoration: underline;
}

.viewscdet {
    font-family: 'inter-md', sans-serif;
    font-size: 22px;
    line-height: 30px;
    margin-top: 40px;
    padding-bottom: 70px;
}

.viewscdet .row + .row {
    margin-top: 30px;
}

.viewscdet-label {
    color: var(--primary);
}

/*Popup Ends*/


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color:#173f5f !important;
}





.hexbars-over.on { animation: coveringOn 3.5s infinite; }

@keyframes coveringOn { 0% { height: 100%; } 100% { height: 0%; } }


.hexbars-over.off { animation: coveringOff 3.5s infinite; }

@keyframes coveringOff { 0% { height: 0%; } 100% { height: 100%; } }


.hexbars-over.paused { animation-play-state: paused; }




.btn-outline-primary{
    color: #000000 !important;
    border-color: #000000 !important;
}
.btn-outline-primary.active{
    background-color: #204664 !important;
    color: white !important;
}

.btn-outline-primary.active:hover{
    background-color: #204664 !important;
}
.btn-outline-primary:hover{
    background-color:transparent !important;
   
}

.save-sched{
    background-color: #204664;
    color: white;
}
.back-sched{
    border-color: #000000;
}

.clrbg1{
    color: blue;
}
.clrbg2{
    color: green;
}
.clrbg3{
    color: red;
}
.clrbg4{
    color: pink;
}
.clrbg5{
    color:yellow;
}
.clrbg6{
    color: aqua;
}
.clrbg7{
color: white;
}
.clrbg8{
color: grey;
}

.clr option{
    background-color: #4D4D4D !important;
}
