@media screen and (max-width: 1910px) {
    body { font-size: 14px; line-height: 24px; }
    .text-18 { font-size: 16px; line-height: 26px; }
    .dpopup-title { font-size: 22px; }
}
/*1910 ENDS*/

@media screen and (max-width: 1860px) {
    .container { max-width: 1340px; }
    .loginbox .tabs-link { font-size: 22px; }
    .dashstatus { font-size: 20px; line-height: 30px; height: 50px; }
    .navbar-list { padding: 18px 21px; }
    .navbar-list .tabs-link { font-size: 20px; line-height: 42px; height: 42px; }
    .navbar-list .tabs-link i { font-size: 28px; }
    .sctabs { font-size: 22px; line-height: 24px; max-width: 875px; padding: 18px 19px; }
    .viewscdet { font-size: 20px; line-height: 30px; }
}
/*1860 ENDS*/

@media screen and (max-width: 1600px) {
    .hpc-logo img { max-width: 40px; }
    .main { min-height: calc(100vh - 73.75px); }
    
    .hexart { width: 189px; }
    .hexart-bb { width: calc(100% - 115px); }
    .hexartp-bb { width: calc(100% - 115px); }
    .hexstatus { font-size: 24px; line-height: 24px; }
    .panelcardbox-co .hexlabel { font-size: 18px; margin-bottom: 6px; }

    .scpanels { padding: 18px; }

    .login-logo { width: 280px; }
    .loginbox { width: 524px; max-width: 100%; padding: 34px 34px; min-height: 0; }
    
    .dpopup { --dpopwid: 660px; }
    .dpopup-title { font-size: 20px; line-height: 1.6; }
    .popup-action .bttn + .bttn { margin-left: 40px; }
}
/*1600*/

@media screen and (max-width: 1439px) {
    .container { max-width: 1170px; }
    .menudrop { left: auto; right: 0; transform: none; }
    .addsched .bttn { font-size: 16px; line-height: 36px; height: 38px; padding: 0 15px; }
}
/*1439*/

@media screen and (max-width: 1366px) {
    .navbar-list .tabs-link { font-size: 18px; line-height: 40px; height: 40px; }
    .navbar-list .tabs-link i { font-size: 25px; }
}
/*1366*/

@media screen and (max-width: 1279px) {
    .viewscdet {  padding: 0 0 20px; }
}
/*1279 ENDS*/

@media screen and (max-width: 1199px) {
    .loginbox { width: 464px; }
    .login-logo { width: 230px; max-width: 100%; }
    .loginbox .tabs-link { font-size: 20px; }
    .loginbox .panels { margin-top: 50px; }
    .addsched { position: static; margin: 0 auto 20px; text-align: right; }
}
/*1199 ENDS*/

@media screen and (max-width: 1024px) {
    .loginbox { width: 404px; padding: 30px; }
    .loginbox .panels-item label { font-size: 16px; }
    
    /*Table Widths*/
    .page-table th, .page-table td { min-width: 0; }
    .page-table th { padding-left: 5px; padding-right: 5px; }
    .page-table th:first-child { padding-left: 0; }
    .page-table th:last-child { padding-right: 0; }
    .page-table th[style] { width: auto !important; }
    .page-table th span, .page-table th:first-child span { width: auto; }
}
/*1024 ENDS*/

@media screen and (max-width: 991px) {
    .notify-close, .bttn-close { font-size: 12px; line-height: 27px; width: 26px; height: 26px; }
    .notify-footer { font-size: 16px; line-height: 24px; padding: 10px 11px 10px; }
    
    .login-left { display: none; }
    .login-right { background-image: linear-gradient(152.11deg, rgba(100, 127, 148, 0.212) 0%, rgba(23, 63, 95, 0.5) 100%); }
    .login-row > div.login-right { flex: 1 0 100%; max-width: 100%; flex-wrap: wrap; min-height: 100vh; align-content: center; }
    .showmob { display: block; flex: 1 0 100%; text-align: center; }
    .login-logo { width: 130px; max-width: 100%; margin-bottom: 30px; }

    
    .pooltemp { font-size: 18px; top: 15px; }
    .panelcardbox { padding-top: 46px; padding-bottom: 70px; }
    .coverprogwrap { padding-bottom: 36px; }
    .dashstatus { font-size: 18px; line-height: 26px; height: 40px; }

    .sctabs { font-size: 14px; line-height: 22px; max-width: 820px; }
    .sctabs-tab + .sctabs-tab { margin-left: 20px; }
    
    .navbar-list .tabs-link { align-content: center; padding: 0 10px; }

    .sensorfail-title i { font-size: 36px; }
    .sensorfail-cont h3 { font-size: 22px; }

    .input-control.input-search { width: 290px; }
}
/*991 ENDS*/

@media screen and (max-width: 767px) {
    .head-alerts .icon-warning { font-size: 22px; line-height: 22px; }
    .headbell .icon-bell { font-size: 23px; line-height: 23px; }
    .headbell-count { font-size: 10px; width: 17px; height: 17px; line-height: 17px; }

    .alert-trashdone.dpopup-alerttxt > i { margin-top: 20px; }
    .viewscdet { font-size: 18px; line-height: 28px; }
    .viewscdet .row + .row { margin-top: 20px; }
    .addsched .bttn { font-size: 14px; line-height: 32px; height: 34px; padding: 0 12px; }
    
    .hexart { width: 169px; }
    .navbar-list .tabs-link { font-family: 'inter-bd', sans-serif; height: auto; padding: 10px; font-size: 14px; line-height: 24px; }
    .navbar-list .tabs-link i { display: block; font-size: 26px; line-height: 30px; width: 100%; margin: 0; }
}
/*767 ENDS*/

@media screen and (max-width: 639px) {
    .dpopup-body { padding: 37px 20px; }
    .dpopup-viewsc .dpopup-body { padding-top: 17px; }

    .main { padding-bottom: 100px; }
    .navbar { position: fixed; left: 0; right: 0; bottom: 0; margin-top: 0; z-index: 1001; }
    .navbar .container { max-width: 100%; padding: 0; }
    .navbar-list { padding: 0; border-radius: 0; box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.13); }
    .navbar-list li { flex: 1 0 22%; max-width: 20%; }
    .navbar-list .tabs-link { border-radius: 0; }
    .navbar-list .tabs-link span { font-size: 13px; }
}
/*639 ENDS*/

@media screen and (max-width: 575px) {
    .head-right>div+div { margin-left: 18px; }
    .head-right>div+div.head-user { margin-left: 20px; }
    .bttn { height: 42px; line-height: 39.8px; }
    .bttn, .sysstat{ font-size: 16px; }
    .bttn.bttn-md { padding: 0 18px; }
    .searchbox .bttn, .bttn-header { font-size: 14px; height: 38px; line-height: 35.8px; }

    .page-card { padding-bottom: 40px; }
    .sctabs { font-size: 14px; line-height: 18px; max-width: 558px; display: flex; flex-direction: column;}
    .sctabs-tab + .sctabs-tab { margin-left: 10px ; }
    .sctab-link { border-radius: 5px; }

    .navbar-list .tabs-link span { font-size: 12px; }

    .dpopup-sensorfail .dpopup-body { padding-top: 20px; }
    .dpopup-head .dpopup-close { top: 10px; right: 10px; }
    .dpopup-title { font-size: 18px; line-height: 1.3; }
    .sensorfail-cont h3 { font-size: 18px; line-height: 1.32em; }
    .sensorfail-cont i { font-size: 36px; }
    .popup-action { margin-top: 26px; }
    .popup-action .bttn { font-size: 14px; }

    .page-table th span { font-size: 14px; line-height: 14px; }

    .page-head { flex-wrap: wrap; }
    .searchbox { flex: 1 0 100%; width: 100%; margin-bottom: 15px; }
    .input-control.input-search { flex: 1 0 calc(100% - 97px); width: calc(100% - 97px); }
}
/*575 ENDS*/

@media screen and (max-width: 459px) {
    .menudrop { top: calc(100% + 5px); }
    .notify { position: fixed; right: 20px; width: calc(100% - 40px); border-radius: 10px; top: 60px; left: auto; }
    
    .navbar-list .tabs-link span { font-size: 11px; }
    .hexlabel { font-size: 25px; line-height: 31px; }

   .sctabs { text-transform: none; max-width: 458px; font-size: 12px; display: flex; flex-direction: column;}
    .addsched { text-align: center; }

    .loginbox { padding: 30px 20px; }
    .loginbox .tabs-link { font-size: 18px; height: 48px; line-height: 48px; }
    .loginbox .panels { margin-top: 40px; }
    .login-right { padding: 30px 20px; }
    .input-control { height: 46px; }
    .form-action { margin-top: 36px; }
    .input-control.input-search { padding-left: 36px; background-size: 16px; background-position: left 10.25px center; }
    .form-inline .input-group { display: block; }
    .userform .input-group label { width: auto; }
    .userform .input-group label::after { display: none; }

    .dpopup-body { padding: 27px 15px; }
    .dpopup-viewsc .dpopup-title { font-size: 20px; }
    .viewscdet { font-size: 16px; line-height: 26px; margin-top: 20px; }
}
/*459 ENDS*/

@media screen and (max-width: 374px) {
}
/*374 ENDS*/

@media screen and (max-width: 359px) {
    .hexart { width: 150px; }
    .hexart-bb { width: calc(100% - 95px); }
    .hexartp-bb { width: calc(100% - 95px); }
    .dashstatus { font-size: 16px; }
    .navbar-list .tabs-link span { font-size: 10px; line-height: 20px; }

    .sctabs { max-width: 290px; font-size: 13px; padding: 0; border-radius: 5px; }
    .sctabs-tab + .sctabs-tab { margin: 0; }
    .page-table th span { font-size: 14px; }

    .dpopup-viewsc .dpopup-title { font-size: 18px; }
}
/*359 ENDS*/

/*Heights*/
@media screen and (min-height: 1080px) {
    .panelcard { min-height: calc(100vh - 80.68px - 102px - 60px - 100px); }
}
/*1080px HEIGHT*/

@media screen and (max-height: 1023px) {
    /*
    .main { padding-bottom: 120px; }
    .navbar { position: fixed; left: 0; right: 0; bottom: 20px; }
    */
    .panelcard { min-height: 529px; }
    .panelcard { min-height: calc(100vh - 73.75px - 94px - 60px - 20px); }
}
/*1023px HEIGHT*/
